import Legend from './legend';
import Village from './village';
import Dungeon from './dungeon';
import Castle from './castle';
import NPC from './npc';

export default {
  village: Village,
  legend:  Legend,
  dungeon: Dungeon,
  castle:  Castle,
  npc: NPC,
}