import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class App extends Component {

  render() {
    return (
      <div className="AppHeader">
        <h1 className="title">Forbidden Lands Toolkit</h1>

        <div className="menu">
          <Link className="m-right-4" to="/g/legend">Legend</Link>
          <Link className="m-right-4" to="/g/village">Village</Link>
          <Link className="m-right-4" to="/g/dungeon">Dungeon</Link>
          <Link className="m-right-4" to="/g/castle">Castle</Link>
          <Link className="m-right-4" to="/g/npc">NPC</Link>
          <Link className="m-right-4" to="/rules">Rules</Link>
        </div>
      </div>
    );
  }
}

export default App;
