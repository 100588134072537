export default [
  {
    title: "Ambush",
    content: [
      "Roll SNEAK +2 vs SCOUT",
      "Can't be moving",
    ],
    tags: ["attack", "combat"]
  },
  {
    title: "Sneak Attack",
    content: [
      "Roll SNEAK vs SCOUT",
      "Distance Modifier: ARM -2, NEAR -1, SMALL +0, LONG +1",
    ],
    tags: ["attack", "combat"]
  },
  {
    title: "Cover",
    content: [
      "Armor Modifiers: Furniture +3, Stone Wall +8",
    ],
    tags: ["combat"]
  },
  {
    title: "Pride",
    content: [
      "Roll extra D12 when relevant after a fail",
      "Once per session",
      "Remove PRIDE if fails",
    ],
    tags: ["skill"]
  },
  {
    title: "Helping",
    content: [
      "Roll extra die per person (max 3)",
    ],
    tags: ["skill"]
  },
  {
    title: "Skill Check",
    content: [
      "BASE dice + SKILL dice + GEAR dice",
      "Can be PUSHED",
    ],
    tags: ["skill"]
  },
  
  {
    title: "Slash",
    content: [
      "Roll MEELE + GEAR BONUS (extra Xs increase damage)",
      "Requires Edged or Blunt weapon",
      "Can be DODGED (target gets +2) or PARRIED",
    ],
    tags: ["combat", "attack", "action", "slow action"]
  },
  {
    title: "Stab",
    content: [
      "Roll MEELE + GEAR BONUS (extra Xs increase damage)",
      "Requires Pointed weapon",
      "Can be DODGED or PARRIED (target gets -2 for weapon, +2 for shield)",
    ],
    tags: ["combat", "attack", "action", "slow action"]
  },
  {
    title: "Punch/Kick/Bite",
    content: [
      "Roll MEELE (deals 1 damage)",
      "Can be DODGED or PARRIED (target gets +2), blunt damage on critical",
    ],
    tags: ["combat", "attack"]
  },
  {
    title: "Swing Weapon",
    content: [
      "If attack is successful, increase damage by +1",
    ],
    tags: ["combat", "attack", "fast action"]
  },
  {
    title: "Grapple",
    content: [
      "Roll MEELE, as no weapon can be used",
      "Can be DODGED or PARRIED",
      "If the attack succeeds, both you and your opponent fall to the ground.",
      "The opponent drops any weapon he was holding, and cannot move.",
      "The only action he can perform is BREAK FREE (slow action) and requires him winning an opposed MELEE against you.",
      "Only action you can perform is a GRAPPLE ATTACK.",
    ],
    tags: ["combat", "attack", "slow action"]
  },
  {
    title: "Dodge",
    content: [
      "Roll MOVE",
      "If the attacker SLASHES, you get a +2 bonus.",
      "Every Xs you roll eliminates a Xs from the attacker’s",
    ],
    tags: ["combat", "reactive action", "fast action"]
  },  
];
