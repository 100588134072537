export default [
  {
    title: "Lead the way",
    content: [
      "Roll SURVIVAL",
      "Success means viable path; on failure suffer mishaps (page 148)",
    ],
    tags: ["journey"]
  },
  {
    title: "Darkness",
    content: [
      "To LEAD THE WAY rolls -2; roll SCOUTING if cannot see in the dark",
      "Failure on scouting deals 1 damage",
      "To RUN in complete darkness requires a successful MOVE roll",
      "TO ATTACK, roll SCOUT first to find the oponent",
    ],
    tags: ["journey", "conditions"]
  },
  {
    title: "Hike",
    content: [
      "SURVIVAL (modified by pathfinder)",
      "Success means viable path; on failure suffer mishaps (page 148)",
    ],
    tags: ["journey"]
  },
  {
    title: "Keep Watch",
    content: [
      "Roll SCOUTING",
      "Success means you detect random encounters",
      "Can HIKE but cannot lead the way",
    ],
    tags: ["journey"]
  },
  {
    title: "Forage",
    content: [
      "SCOUTING modified by terrain type and season (page 151 PH)",
      "Success means food equal to Xs or d12 water",
      "Failure suffer mishaps (page 150 PH)"
    ],
    tags: ["journey"]
  },
  {
    title: "Hunt",
    content: [
      "Find the prey with SURVIVAL",
      "On success roll to find type of prey",
      "Extra Xs allow re-rolls",
      "KILL the prey use either MARKSMANSHIP or SURVIVAL (modify by animal)",
    ],
    tags: ["journey"]
  },
  {
    title: "Fish",
    content: [
      "",
      "",
    ],
    tags: ["journey"]
  },
  {
    title: "Rest",
    content: [
      "Recover all lost attribute points",
      "Only works if you are not BROKEN",
    ],
    tags: ["journey"]
  },
  {
    title: "Make camp",
    content: [
      "Find a good location, making fire, cook, prepare where to SLEEP",
      "It takes a whole Quarter Day",
      "One person rolls SURVIVAL",
      "On success, you find a sheltered and confortable place to REST",
      "On fail, you still REST but roll for MISHAP (PH 155)",
    ],
    tags: ["journey"]
  },
  {
    title: "Sleep",
    content: [
      "You have to SLEEP at least one Quarter Day per day to avoid becoming SLEEPY (see page 111)",
      "If interrupted no longer counts as SLEEP",
    ],
    tags: ["journey"]
  },
  {
    title: "Explore",
    content: [
      "When you stop at an adventure site to EXPLORE it, your journey is interrupted",
      "Can take anything from a Quarter Day up to several days or even weeks",
    ],
    tags: ["journey"]
  },
  {
    title: "Sea Travel",
    content: [
      "",
      "",
    ],
    tags: ["journey"]
  },  
]
