import React, { Component } from 'react';

import Rule from './Rule';

import RULES from '../constants/rules/rules';
import {matchRule, getTags} from '../utils/rules';

class Generator extends Component {

  constructor(props) {
    super(props);
    this.state = { query: '' }
    this.updateQuery = this.updateQuery.bind(this);
  }

  updateQuery(e){
    this.setState({ query: e.target.value })
  }

  queryRules(){
    const {query} = this.state;
    return RULES.filter(i => { return matchRule(i, query); });
  }

  render(){
    const rules = this.queryRules();
    const tags = getTags(RULES);

    return (
      <div className="generated">
        <div className="m-bottom-6">
          <input
            autoFocus
            className="rules__query"
            value={this.state.query}
            onChange={this.updateQuery}
            placeholder="Search rule..." />
        </div>

        <div>
          {/* {tags.map(tag => (<span>{tag}</span>) )} */}
        </div>

        <div>
          {rules.map(rule => <Rule rule={rule} />)}
        </div> 
      </div>
    )
  }
}

export default Generator;
