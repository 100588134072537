export default [
  {
    before: "<h2>Castle</h2>",
    after: "",
    options: [],
  },
  {
    before: "<strong>Name:</strong> ",
    after: " ",
    options: [
      "Stone",
      "Red",
      "Purple",
      "Blood",
      "Dead",
      "Winter",
      "Oak",
      "Blue",
      "Snow",
      "Wind",
      "Storm",
      "Fire",
      "Rust",
      "Griffon",
      "Dark",
      "Dim",
      "Wood",
      "Barren",
      "Deep",
      "Alder",
      "Rain",
      "Eagle",
      "Wolf",
      "Bear",
      "Dragon",
      "Frost",
      "Night",
      "Rose",
      "Fang",
      "River",
      "Sea",
      "Ice",
      "Sun",
      "Moon",
      "Wolf",
      "Twilight",
    ],
  },
  {
    before: " ",
    after: "<br/> ",
    options: [
      "Hook",
      "Tooth",
      "Spire",
      "House",
      "Claw",
      "Jaw",
      "Water",
      "Redoubt",
      "Mound",
      "Hill",
      "Mountain",
      "Tower",
      "House",
      "Edge",
      "Home",
      "Cliff",
      "Pike",
      "Eye",
      "Hole",
      "Gap",
      "Shield",
      "Nest",
      "Mark",
      "Grove",
      "Glade",
      "Keep",
      "Fortress",
      "Castle",
      "Keep",
      "Lair",
      "Stone",
      "Den",
      "Wall",
      "Mound",
      "Mark",
      "Fort",
    ]
  },
  {
    before: "<strong>Age of the dungeon:</strong> ",
    after: "<br />",
    options: [
      "before the Shift (1,100+)",
      "before the Blood Mist (300–1,100)",
      "during the Alder Wars (305-360)",
      "in the age of the Blood Mist (5–280)"
    ],
  },
  {
    before: "<strong>Purpose:</strong> ",
    after: "<br />",
    options: [
      "Outpost",
      "Blockhouse",
      "Tower",
      "Fort",
      "Keep",
      "Fortress",
      "Palace",
    ],
  },
  {
    before: "<strong>Oddity:</strong> ",
    after: "<br />",
    options: [
      "Odd architecture",
      "Blood colored",
      "Surrounded by ravens",
      "Built out of a massive rock",
      "Weird inscriptions",
      "Surrounded by graves",
      "Built on a battlefield",
      "Haunted",
      "Bad smell",
      "Strange sounds",
      "Covered by purple flowers",
      "Always rains",
      "Built around an ancient statue",
      "Windy",
      "Fungal fields",
      "Blackened by soot",
      "Decorated with skulls",
      "Overgrown",
    ],
  },



]
