export default [
  {
    before: "<h2>Village</h2>",
    after: "",
    options: [],
  },
  {
    before: "<strong>Age of the village:</strong> ",
    after: "<br />",
    options: [
      "before the Shift (1,100+)",
      "before the Blood Mist (300–1,100)",
      "during the Alder Wars (305-360)",
      "in the age of the Blood Mist (5–280)"
    ],
  },
  {
    before: "<strong>The ruler of the village:</strong> ",
    after: "",
    options: [
      "Bickering",
      "Cruel",
      "Weak",
      "Greedy",
      "Wise",
      "Eccentric",
      "Confusing",
      "Brutal",
      "Cunning",
      "Stern",
      "Secret",
      "Drunkard",      
    ]
  },
  {
    before: " ",
    after: "<br />",
    options: [
      "Council",
      "Despot",
      "Elder",
      "Mayor",
      "Druid",
      "Sorcerer",
      "No one",
      "Commander",
      "Trader",
      "Rust Brother",
      "Artisan",
      "Bandit Chief",
    ]
  },
  {
    before: "<strong>The problem:</strong> ",
    after: "<br /> ",
    options: [
      "Nightwargs",
      "Widespread Drunkenness" ,
      "Power Struggle",
      "Secret Cult",
      "Schism",
      "Undead",
      "Disease",
      "Sinkhole",
      "Bandits",
      "Terrorizing Monster",
      "Slave Trade",
      "Haunted by Ghoul or Ghost",
    ]
  },
  {
    before: "<strong>Famous for</strong>: ",
    after: "<br /> ",
    options: [
      "Excellent Wine",
      "Delicious Bread",
      "Craftsmanship",
      "Beautiful Location",
      "A Horrible Massacre",
      "Decadence",
      "Well–Brewed Beer",
      "Hidden Riches",
      "Strange Disappearances",
      "Worshipping Demons",
      "Suspicion of Strangers",
      "Hospitality"
    ]
  },
  {
    before: "<strong>Oddity:</strong> ",
    after: "<br /> ",
    options: [
      "Eccentric Clothing",
      "Incomprehensible Accent",
      "Smells Bad",
      "Full of Flowers",
      "Muddy",
      "Odd Building Materials",
      "Tent Village",
      "Built on Steep Hill",
      "Old Tower in the Middle",
      "Grand Building",
      "Windy",
      "Inbreeding",
      "Strange Eating Habits",
      "Built on Marshland",
      "Cut Out of a Cliff",
      "Old Burial Site",
      "Wandering Cattle",
      "Mostly Inhabited by Women",
      "Allied with Monster",
      "Preparing Wedding"
    ]
  },
  {
    before: "<strong>The Village Inn</strong>: ",
    after: " ",
    options: [
      "The Third",
      "The Little",
      "The Red",
      "The Misty",
      "The Bloody",
      "The Old",
      "The Golden",
      "The Cold",
      "The Refreshing",
      "The Good",
      "The Second",
      "The Last",
      "The Prancing",
      "The Happy",
      "The Singing",
      "The Rolling",
      "The Rumping",
      "The Wailing",
      "The Greedy",
      "The Round",
      "The Flaming",
      "The Last",
      "The Silver",
      "The Black",
      "The Dead",
      "The Big",
      "The Roaring",
      "The Cheering",
      "The Humming",
      "The Meagre",
      "The Fat",
      "The Thick",
      "The Round",
      "The Sweet",
      "The Boisterous",
      "The Grumpy",
    ]
  },
  {
    before: "",
    after: "</h2>",
    options: [
      "Lantern",
      "Swine",
      "Wheel",
      "Stoop",
      "Sparrow",
      "Goat",
      "Arrow",
      "Pot",
      "Lamb",
      "Maid",
      "Man",
      "Dragon",
      "Griffin",
      "Boar",
      "Barrel",
      "Bard",
      "Dog",
      "Horse",
      "Girl",
      "Wolf",
      "Bear",
      "Ghost",
      "Rat",
      "Jar",
      "Mug",
      "Goblet",
      "Eagle",
      "Raven",
      "Hammer",
      "Spike",
      "Crow",
      "Druid",
      "Knight",
      "Bandit",
      "Wild Boar",
      "Hunter",
    ]
  },

  {
    before: "<br />",
    after: " (Oddity), ",
    options: [
      "Violence is in the air",
      "Barrels instead of chairs, planks instead tables",
      "Big fireplace",
      "Pelts on walls",
      "Long communal table",
      "Gambling den",
      "Mediocre bard",
      "Nice dog",
      "Grumpy owner",
      "Monster head on wall",
      "Singing waiter",
      "Stomped floor",
      "Birthday party",
      "Drunk adventurers",
      "Hidden entrance",
    ]
  },
  {
    before: "",
    after: " (Speciality), ",
    options: [
      "Cheap diluted beer",
      "Meat stew",
      "Grilled rodent",
      "Stewed turnips",
      "Salt bird",
      "Blood soup",
      "Fiery spice wine",
      "Roasted piglet",
      "Swamp stew",
      "Vegetable mush",
      "Salted fish",
      "Cooked crow",
      "Bear stew",
      "Dwarven strong ale",
    ]
  },
  {
    before: "",
    after: " (Special Guest)<br>",
    options: [
      "Escaped criminal",
      "Unhappy farmer",
      "Scarred treasure hunter",
      "Dirty and sullen hunter",
      "Silent Raven sister",
      "Noisy bandit",
      "Old war veteran",
      "Noble in disguise",
      "Secretive spellbinder",
      "Annoying jester",
      "Dusty traveller",
      "Hungry dwarf",
      "Frosty elf",
      "Scouting thief",
    ]
  }
]
