import ReactHtmlParser from 'react-html-parser';

export function toHex(num){
  let hexString = num.toString(16);
  if (hexString.length % 2) {
    hexString = '0' + hexString;
  }
  return hexString;
}

export function toInt(num){
  return parseInt(num, 16);
}

export function getRandom(arr){
  return arr[getRandomIndex(arr)];
}

export function getRandomIndex(arr){
  return Math.floor(Math.random() * arr.length);
}

export function parseHash(hash){
  if(!hash) { return null; }
  
  return hash
    .match(/.{1,2}/g)
    .map(i => toInt(i))
}

export function generateObj(generator, hash){
  const hashArr = parseHash(hash);

  return generator.map((i, ii) => {
    let index = hashArr
      ? hashArr[ii]
      : getRandomIndex(i.options)

    return {
      id: index,
      hex: toHex(index),
      value: [i.before, i.options[index], i.after].join('')
    }
  });
}

export function generate(generator, hash){
  const obj = generateObj(generator, hash);

  return {
    hash: obj.map(i => i.hex).join(''),
    values: obj.map(i=> i.value),
    value: ReactHtmlParser(obj.map(i=> i.value).join(''))
  };
}