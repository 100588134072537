import React, { Component } from 'react';

class Rule extends Component {

  render() {
    const { rule } = this.props;
    return (
      <div>
        <h3 className="m-0">{rule.title}</h3>
        <div>{rule.roll}</div>
        <small>{rule.description}</small>
        <small>
          <ul>
            {rule.content && rule.content.map(row => <li>{row}</li>)}
          </ul>
        </small>
        <hr />
      </div>
    );
  }
}

export default Rule;


