export default [
  {
    title: "Gaining Experience (XP)",
    content: [
      "Did you participate in the game session?",
      "Did you travel through at least one hex on the game map that you had not visited before?",
      "Did you discover a new adventure site?",
      "Did you defeat one or more monsters?",
      "Did you find a treasure (1 gold or more)?",
      "Did you build a function in your stronghold?",
      "Did you activate your Pride?",
      "Did you suffer from your Dark Secret?",
      "Did you risk your life for another PC?",
      "Did you perform an extraordinary action of some kind?",      
    ],
    tags: ["xp"]
  },
  {
    title: "Spending Experience (XP)",
    content: [
      "During a quarter of day when you REST or SPLEEP",
      "SKILLS: Skill Level x 5",
      "TALENTS: Talent Rank x 3",
      "MAGIC: Require a teacher with higher rank, without it the cost is x3",
    ],
    tags: ["xp"]
  },
  {
    title: "Reputation",
    content: [
      "When you arrive at a settlement roll REPUTATION x D6 ",
      "On success people have heard from you",
      "KIN: If settlement primary populated by another kin, REPUTATION -2",
      "THE GROUP: Roll for the highest for the entire group",
      "MANIPULATION: can affect manipulation score",
    ],
    tags: ["reputation"]
  },
  
  {
    title: "Gaining Reputation",
    content: [
      "A prominent NPC was killed, or saved from certain death.",
      "A feared monster was slain.",
      "A legendary treasure or artifact was found or stolen.",
      "The deed permanently altered the overall situation in an adventure site.",
      "The deed will have significant consequences for one of the major powers in the Forbidden Lands.",
      "You have built a certain function in your stronghold (see page 160).",
    ],
    tags: ["reputation"]
  },
]
