export default [
  {
    before: "<h2>NPC</h2> ",
    after: "",
    options: []
  },
  {
    before: "<strong>Occupation:</strong> ",
    after: "<br/>",
    options: [
      "Soldier",
      "Fortune",
      "Seeker",
      "Bandit",
      "Slave Trader",
      "Treasure",
      "Hunter",
      "Beggar",
      "Actor",
      "Lumberjack",
      "Hunter",
      "Farmer",
      "Laborer",
      "Jester",
      "Wanderer",
      "Fisher",
      "Noble",
      "Child",
      "Trader",
      "Brewer",
      "Carpenter",
      "Apprentice",
      "Thief",
      "Druid",
      "Baker",
      "Refugee",
      "Assassin",
      "Smith",
      "Gravedigger",
      "Rust Brother",
      "Shepherd",
      "Braggart",
      "Cook",
      "Cultist",
      "Guard",
      "Messenger",
      "Miner",
      "Academic",
    ]
  },
  {
    before: "<strong>Characteristic:</strong> ",
    after: "<br />",
    options: [
      "Ice blue eyes",
      "Fluffy hair",
      "Scarred face",
      "Severed ear",
      "Eye patch",
      "Missing teeth",
      "Graceful",
      "Towering",
      "Impressive beard or hair",
      "Round cheeks",
      "Distinct birthmark",
      "Strange facial",
      "paint",
      "Sad eyes",
      "Smells bad",
      "Perfumed",
      "Cocky",
      "Constantly smiling",
      "Sullen",
      "Constantly joking",
      "Lovesick",
      "Elegant",
      "Tattooed",
      "Constantly eating",
      "Desperate",
      "Hateful",
      "Sweaty",
      "Drunk",
      "Suspicious",
      "Very beautiful",
      "Foppish",
      "Fat",
      "Seducing",
      "Lazy",
      "Very tired",
      "Wiry",
      "Unkempt eyebrows",
    ]
  },
  {
    before: "<strong>Quirk:</strong> ",
    after: "<br/>",
    options: [
      "Dreaming about a family and a house. Will do anything to realize it.",
      "Skilled at gambling. Booted from three villages.",
      "Haunted by dreams of a family dying in a fire. Sometimes hallucinates.",
      "Chewing on narcotic leaves, staring glare and foul mood.",
      "Very enthusiastic. Has a treasure map but no surviving friends.",
      "Alder spy in disguise. Will not hesitate to kill.",
      "Too sensitive for this cruel world.",
      "Squeamish and weepy.",
      "Obsessed with finding a special monster. Will not give up until one of them is dead.",
      "Cultist, looking for new victims.",
      "Sleeps badly, sees holy visions at night.",
      "Legendary pickpocket constantly changing identities.",
      "Only survivor of a burnt down village.",
      "Pyromaniac.",
      "Worships the god in the deep.",
      "Possessed by quirky demon.",
      "Planning to avenge dead parent.",
      "Bankrupt, but has a grand plan to save the business. Just needs some help.",
      "Hates a competitor and will do anything to get rid of him or her.",
      "Hides a dark secret.",
      "Will do anything to achieve dream. Anything.",
      "Expensive habits. Leaves tab to others",
      "Likes to scare people.",
      "Believes self to be of royal lineage.",
      "Wants revenge more than anything.",
      "Interested in poetry.",
      "A notorious cheat.",
      "Knows a lot about people. Gossipy.",
      "Has doubts.",
      "Dreams of killing someone.",
      "Likes to touch people as if old friends.",
      "A brilliant storyteller.",
      "Will take over, one day.",
      "Craving for a mug of beer.",
      "Believes he or she is being followed.",
      "Swears all the time.",
      "Has a theory about the world. Wants to tell everyone about it.",
    ]
  }
]



