import actions from './actions';
import damage from './damage';
import journey from './journey';
import experience from './experience';

import {sortByTitle} from '../../utils/rules';

export default [
  ...sortByTitle(actions),
  ...sortByTitle(damage),
  ...sortByTitle(journey),
  ...sortByTitle(experience),
];
