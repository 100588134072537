import React, { Component } from 'react';
import './App.css';

import Generator from './components/Generator';

class App extends Component {

  render() {
    const {generator,hash} = this.props.match.params;

    return (
      <div>
        {generator ?
          <Generator generator={generator} hash={hash} />
        : null}
      </div>
    );
  }
}

export default App;
