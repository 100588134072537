export default [
  {
    before: "<h2>Dungeon</h2>",
    after: "",
    options: [],
  },
  {
    before: "<strong>Age of the dungeon:</strong> ",
    after: "<br />",
    options: [
      "before the Shift (1,100+)",
      "before the Blood Mist (300–1,100)",
      "during the Alder Wars (305-360)",
      "in the age of the Blood Mist (5–280)"
    ],
  },
  {
    before: "<strong>Original Purpose:</strong> ",
    after: "<br />",
    options: [
      "Temple",
      "Stronghold",
      "Living Area",
      "Hiding Place",
      "Mine",
      "Prison",
      "Tomb",
    ],
  },
  {
    before: "<strong>Who built it:</strong> ",
    after: "<br />",
    options: [
      "No one, it was created naturally",
      "Elves",
      "Dwarves",
      "Orcs",
      "Priest",
      "Cult",
      "Sorcerer",
      "Warrior",
      "Noble",
      "Bandit Chief",
      "Demon",
    ],
  },
  {
    before: "<strong>History:</strong> ",
    after: "<br />",
    options: [
      "Ravaged by disease",
      "Perished during a flood",
      "Builders died of hunger",
      "Builders wiped out by magical experiments",
      "Resources dwindled and builders",
      "Builders destroyed by strange artifact",
      "Haunted by the restless dead of",
      "Lost after internal strife",
      "Invaded by enemies",
      "Invaded by demons",
      "The builders still live here",
    ],
  },
  {
    before: "<strong>Inhabitants:</strong> ",
    after: "<br />",
    options: [
      "Sorcerers",
      "Bandits",
      "Slave Traders",
      "Orcs",
      "Goblins",
      "Whiners",
      "Bats",
      "Giant Spiders",
      "Dwarves",
      "Saurians",
      "Ogres",
      "Undead",
      "Ghost",
      "Insectoids",
      "Minoutaurs",
      "Strangling Vine",
      "Nightwargs",
      "Abyss Worm",
      "Giant Squid",
      "Sea Serpent",
      "Bloodling",
      "Hydra",
      "Manticore",
      "Drakewyrm",
      "Demon",
      "Dragon",
    ],
  },
  {
    before: "<strong>Entrance:</strong> ",
    after: "<br />",
    options: [
      "Below a building",
      "Shaped as a gaping jaw",
      "In or below a gnarled tree",
      "A heavy stone door",
      "On a cliff edge",
      "Down a hole",
      "Alongside a decaying statue",
      "of the founder",
      "Below water",
      "A cavern",
      "Covered in moss",
      "In the bottom of a deep ravine",
      "In a tower",
      "In a hill",
      "Below a mausoleum",
      "Behind a door of bone",
      "A stairway down into the deep",
      "Behind heavy iron bars that close and trap the adventurers",
    ],
  },
  {
    before: "<strong>Oddity:</strong> ",
    after: "<br />",
    options: [
      "Unnaturally Hot",
      "Humid",
      "Pools of Water",
      "Smells of Death",
      "Mushrooms",
      "Strange Flowers",
      "Slimy Walls",
      "Dried Blood",
      "Wall Paintings",
      "Weird Echoes",
      "Large Cracks",
      "Statue",
      "Alcoves",
      "Altar",
      "Moss",
      "Dripping Sounds",
      "A Gnarly Tree",
      "Pulsating Sounds",
      "Mummified Corpse",
      "Fireplace",
      "Abyss",
      "Well",
      "Weirdly Shaped Skull",
      "Mosaic",
      "Petrified Creature",
      "Empty Sarcophagus",
      "Ash in the Form of a Human",
      "Snakes",
      "Insects",
      "Rats",
      "Bats",
      "Spider Webs",
      "Skeleton Parts on Floor",
      "Warning Written in Blood",
      "Lost Adventurer",
      "Roll twice on this table",
    ],
  },
]
