export default [
  {
    before: "<h2>Legend</h2>",
    after: "",
    options: [],
  },
  {
    before: "A long time ago ",
    after: " ",
    options: [
      "before the Shift (1,100+)",
      "before the Blood Mist (300–1,100)",
      "during the Alder Wars (305-360)",
      "in the age of the Blood Mist (5–280)"
    ],
  },
  {
    before: "there was a ",
    after: " ",
    options: [
      "Bloodthirsty",
      "Vengeful",
      "Greedy",
      "Unhappy in love",
      "Ingenious",
      "Enterprising",
      "Kind",
      "Perseverant",
      "Treacherous",
      "Moral",
      "Skilled",
      "Stingy",
      "Vain",
      "Wise",
      "Beautiful ",
      "Honorable",
      "Jealous",
      "Cruel",
      "Determined",
      "Cunning",
      "Scared",
      "Evil",
    ]
  },
  {
    before: "",
    after: " ",
    options: [
      "Elf",
      "Dwarf",
      "Peddler",
      "Smith",
      "Farmer",
      "Apprentice",
      "Druid",
      "Shepherd",
      "Raven Sister",
      "Rust Brother",
      "Rider",
      "Treasure Hunter",
      "Priest",
      "Sorcerer",
      "Robber Chieftain",
      "Warrior",
      "Lord",
      "Prince",
      "Princess",
      "Queen",
      "King",
    ],
  },
  {
    before: "who sought ",
    after: " ",
    options: [
      "a weapon",
      "a love",
      "a friend in need",
      "an enemy",
      "a treasure",
      "a map",
      "a family member",
      "an artifact",
      "a monster",
    ]
  },

  {
    before: "because of ",
    after: " ",
    options: [
      "love",
      "friendship",
      "a promise",
      "a prophecy",
      "a bet",
      "duty",
      "war",
      "honor",
      "insanity",
      "dreams",
      "greed",
    ]
  },
  {
    before: " and traveled to ",
    options: [
      "a ruin",
      "a farm",
      "a grave",
      "a tower",
      "a castle",
      "a village",
      "a cave",
      "a hill",
      "a tree",
      "a water source",
    ]
  },
  {
    before: " located ",
    options: [
      "around here",
      "close by",
      "a day’s march away",
      "a few days off",
      "far away",
      "on the other side of the Forbidden Lands",
    ]
  },
  {
    before: " in ",
    options: [
      "some Ruins",
      "some Marshlands",
      "a Quagmire",
      "some Plains",
      "a Forest",
      "some Hills",
      "a Dark Forest",
      "a Lake",
      "some Mountains",
    ]
  },
  {
    before: " in the ",
    after: " direction. ",
    options: [
      "North",
      "North-East",
      "East",
      "South-East",
      "South",
      "South-West",
      "West",
      "North-West",
    ]
  },
  {
    before: "As the legend goes, it is said that he/she ",
    options: [
      "was betrayed",
      "was murdered",
      "was never seen again",
      "starved to death",
      "took his/her own life",
      "died in battle",
      "was enchanted",
      "was possessed",
      "came back changed",
      "still searches",
    ]
  },
  {
    before: " and that at the location there is/are ",
    options: [
      "gold, lots of gold",
      "a powerful artifact",
      "a suit of armor",
      "a weapon",
      "an invaluable book",
      "a large treasure",
      "a lost war chest",
      "the remains of an important person",
      "a dwarven artifact",
      "an elven ruby",
    ]
  },
  {
    before: " but also ",
    options: [
      "Aggressive",
      "Bloodthirsty",
      "Cruel",
      "Horrible",
      "Hungry",
      "Watchful",
      "Starved",
      "Greedy",
      "Insane",
      "Murderous",
      "Manic",
      "Hunting",
    ],
  } ,
  {
    before: " ",
    after: ".",
    options: [
      "Wolfkin",
      "Slave Traders",
      "Orcs",
      "Ghosts",
      "Saurians",
      "Iron Guards",
      "Undead",
      "Robbers",
      "Goblins",
      "Ogres",
      "Monsters*",
      "Demons**",
    ]
  }
]
