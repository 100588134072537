import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './index.css';

import * as serviceWorker from './serviceWorker';

import App from './App';
import AppHeader from './components/AppHeader';
import Generators from './components/Generators';
import Rules from './components/Rules';

const rootEl = document.getElementById('root');

ReactDOM.render(
  <Router>
    <div class="App">
      <AppHeader />

      <Route exact path="/rules" component={Rules} />
      <Route exact path="/g/:generator/:hash" component={App} />
      <Route exact path="/g/:generator" component={Generators} />
      <Route exact path="/" component={App}/>
    </div>
  </Router>,
  rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
