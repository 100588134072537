import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

import GENERATORS from '../constants/generators/generators';
import {generate} from '../utils/generators';

class Generators extends Component {

  render(){
    const {generator} = this.props.match.params;
    const generated = generate(GENERATORS[generator]);
    const link = `/g/${generator}/${generated.hash}`;

    return (
      <Redirect to={link} />
    )
  }
}

export default Generators;
