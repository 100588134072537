import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import GENERATORS from '../constants/generators/generators';
import {generate} from '../utils/generators';

class Generator extends Component {

  render(){
    const {generator, hash} = this.props;
    const generated = generate(GENERATORS[generator], hash);
    const link = `/g/${generator}/${generated.hash}`;

    return (
      <div className="generated">
        <div>{generated.value}</div>
        <small>
          <Link className="m-right-4" to={link}>{generated.hash}</Link>
        </small>
      </div>
    )
  }
}

export default Generator;
