export function matchField(value, q){
  return Boolean(value) && value.toLowerCase().indexOf(q) >= 0;
}

export function compareTitle(a,b) {
  if (a.title < b.title){ return -1; }
  if (a.title > b.tutke){ return 1; }
  return 0;
}

export function matchRule(i, query){
  const q = query.toLowerCase();
  const matchTitle = matchField(i.title, q);;
  const matchRoll = matchField(i.roll, q);
  const matchDescription = matchField(i.description, q);
  const matchTags = i.tags.join(' ').indexOf(q) >= 0;

  return matchTitle || matchRoll || matchDescription || matchTags;
}

export function sortByTitle(rules){
  return rules.sort(compareTitle);
}

export function getTags(rules){
  let tags = new Set();
  rules.forEach(rule => rule.tags.forEach(tag => tags.add(tag)));
  return [...tags].sort();
}
