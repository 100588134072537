export default [
  {
    title: "Hungry",
    content: [
      "You cannot recover Strength in any way, except through magic",
      "You suffer 1 point of damage to Strength per week",
      "If your Strength is Broken while HUNGRY, you die after another week without food",
      "As soon as you have eaten, you are no longer HUNGRY"
    ],
    tags: ["condition"]
  },
  {
    title: "Thirsty",
    content: [
      "You must drink a ration of water at least once per day",
      "You suffer 1 point of damage to Strength per week",
      "After a day without water, you become THIRSTY.",
      "You cannot recover any attributes, except through magic",
      "You take 1 point of damage to both Strength and Agility every day",
      "If either of your Strength or Agility are Broken while THIRSTY, you die after another day without water",
      "As soon as you drink, you are no longer THIRSTY"
    ],
    tags: ["condition"]
  },
  {
    title: "Sleepy",
    content: [
      "Cannot recover Wits, except through magic",
      "You take 1 point of damage to your Wits each day",
      "If this damage breaks your Wits, you collapse and sleep for at least one Quarter Day",
      "As soon as you have slept for at least a Quarter Day, you are no longer SLEEPY"
    ],
    tags: ["condition"]
  },
  {
    title: "Cold",
    content: [
      "When the cold is bitter and you don’t have enough shelter, you have to roll ENDURANCE regularly",
      "A chilly fall day requires a roll per day",
      "You can even hallucinate, causing you to act irrationally – details are up to the GM to decide",
      "If your Strength is Broken while you are COLD, you die the next time you have to roll",
    ],
    tags: ["condition"]
  },
  {
    title: "Fear",
    content: [
      "A fear attack is rolled a number of Base Dice",
      "Each X rolled causes 1 point of damage to Wits",
      "All fear attacks have NEAR range, unless stated otherwise",
    ],
    tags: ["damage"]
  },
  {
    title: "Falling",
    content: [
      "Falls from three meters or more and hit a hard surface, roll for an attack",
      "Roll Base Dice equal to height (in meters), minus 2",
      "Take 1 point of damage to Strengh for every X",
      "Metal armor gives no protection",
    ],
    tags: ["damage"]
  },  
  {
    title: "Drowning",
    content: [
      "If you end up in water, you need to make an ENDURANCE roll every turn (15 minutes) to stay afloat",
      "If you wear metal armor, you need to roll every round",
      "If you sink, you need to make an ENDUR- ANCE roll every round to hold your breath",
      "If you fail, you start to drown and suffer 1 point of damage to Strength every round until someone saves you",
      "If you are Broken when drown- ing, you die after D6 minutes"
    ],
    tags: ["damage"]
  },
  {
    title: "Poison",
    content: [
      "Poisons are measured by Potency: Weak 3, Strong 6, Extremely Potent 9+",
      "If you ingest poison in some manner, roll an opposed ENDURANCE vs POTENCY",
      "If the poison wins, you suffer its full effect",
      "If you win the roll, you only suffer the limited effect of the poison.",
    ],
    tags: ["damage"]
  }, 
  {
    title: "Disease",
    content: [
      "When exposed to a dangerous contagion or infection,roll an opposed ENDURANCE x Virulence",
      "If you fail the roll, you fall SICK, which has several effects:",
      "The day after the infection the disease breaks, at which time you suffer 1 point of damage to both Strength and Agility.",
      "You can’t recover your Strength or Agili- ty while SICK, except through magic",
      "Make another sickness roll once per day.",
      "If your Strength is Broken when SICK, you die after another day if you don’t get well before then.",
      "As soon as you succeed at a sickness roll, you are no longer SICK."
    ],
    tags: ["damage"]
  },        
]
